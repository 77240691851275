
// React Import 
import React, {useEffect, useState} from 'react';
import TrackVisibility from 'react-on-screen';


// CSS Import 
import "./Road_Map_Page.css"


const RoadMapPage: React.FC = () => {


  return(
    <div className='Body_Div_Road_Map' id='roadMap'>

      <div className='Body_Div_Road_Map_Title'>

        <div className='Body_Div_Road_Map_Title_Title'>
          <p>Road Map</p>
        </div>
        <div className='Body_Div_Road_Map_Title_Line  '>

        </div>

      </div>


      <div className='Body_Div_Road_Map_Sub_Contr'>

        <div className='Body_Div_Road_Map_Steps'>
          <p className='Body_Div_Road_Map_Steps_Title'>PRESALES</p>
          <p className='Body_Div_Road_Map_Steps_Text'> 
            The SSOLI presales will commence .
          </p>
        </div>

        <div className='Body_Div_Road_Map_Steps'>
          <p className='Body_Div_Road_Map_Steps_Title'>LAUNCH</p>
          <p className='Body_Div_Road_Map_Steps_Text'> 
            Once the presales is complated, the token will be listed on the market .
          </p>

        </div>

        <div className='Body_Div_Road_Map_Steps'>
          <p className='Body_Div_Road_Map_Steps_Title'>UPDATE</p>
          <p className='Body_Div_Road_Map_Steps_Text'> 
            We will implement a major site update that includes art competitions, game 
            tournements, rental services, and much more all utilizeing SSOLI for payment.
          </p>

        </div>

        <div className='Body_Div_Road_Map_Steps' >
          <p className='Body_Div_Road_Map_Steps_Title'>AIRDROP</p>
          <p className='Body_Div_Road_Map_Steps_Text'> 
          With the Sonic update on the Fantom network, we will release a meme token and
          distribute it via airdrop to our presale participants and holders, without an 
          additional presale.
          </p>

        </div>

      </div>


    </div>

  );

}

export default RoadMapPage;