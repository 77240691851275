import { useState } from 'react';

// Solana Import
import { 
  useAnchorWallet }
  from "@solana/wallet-adapter-react";

// Third Party Improt 
import { 
  sellTransactnButtonsInputDfns,
  refundTransactnButtonsInputDfns,
  TokenBuyViaSmartContract,
  TokenRefundViaSmartContract
} from './Utils_Func';

import {TokenAmountCalculator, SolAmountCalculator} from './Utils_Comp';

import 
  CurrencyInput, 
  { CurrencyInputProps, CurrencyInputOnChangeValues } 
from 'react-currency-input-field';



// CSS Import 
import './Transaction_Page.css';

// Bootstrap Import 
import Button from 'react-bootstrap/Button';


// Third Party Import 

import { userSalesAreaDivInputDfns } from './Utils_Func';

// Some Define Section 
const initalValues: CurrencyInputOnChangeValues ={
  float : 0.0,
  formatted: "0.0",
  value : "0.0"
}

// Export Item Section 

/* Transaction Buttons 
* Include Transaction Button Sectino  
*/
export const PresalesButton = (
  {userAnchorWallet, solAmount}:sellTransactnButtonsInputDfns
  ) => {

  return(
    <div className="Wallet_Button_Div">
      <Button onClick={() => TokenBuyViaSmartContract({userAnchorWallet, solAmount})} 
      className="Button_PreSales" variant="outline-primary">
        Pre-Sales Token 
      </Button>{' '}
    </div>
  );

};


export const RefundButton = (
  {userAnchorWallet, tokenAmount}:refundTransactnButtonsInputDfns
  ) => {

  return(
    <div className="Wallet_Button_Div">
      <Button onClick={() => TokenRefundViaSmartContract({userAnchorWallet, tokenAmount})} 
      className="Button_ReFund" variant="outline-primary">
        Refund Token
      </Button>{' '}
    </div>
  );

};


/* User Sales Area 
* That area include amount calculator and transaction buttons 
*/
export const UserSalesArea = ({isBuyActive}: userSalesAreaDivInputDfns) => {
  const [tokenAmountInput, setTokenAmount] = useState<CurrencyInputOnChangeValues>();
  const [classNameSol2Token, setClassNameSol2Token] = useState('');
  
  const [solAmountInput, setSolAmount] = useState<CurrencyInputOnChangeValues>();
  const [classNameToken2Sol, setClassNameToken2Sol] = useState('');
  const userAnchorWallet = useAnchorWallet();
  
  const handleOnValueChangeSol2Token: CurrencyInputProps['onValueChange'] = (_value, name, _values) => {
    
    if (!_value) {
      setTokenAmount(initalValues);
      setClassNameSol2Token('');
      return;
    }
    setTokenAmount(_values);

    // Percentage Class Settings
    let classStage = _values?.float! % 100 ; 
    setClassNameSol2Token(`rate_${classStage}`)

  };

  const handleOnValueChangeToken2Sol: CurrencyInputProps['onValueChange'] = (_value, name, _values) => {
    
    if (!_value) {
      setSolAmount(initalValues);
      setClassNameToken2Sol('');
      return;
    }
    setSolAmount(_values);

    // Percentage Class Settings
    let classStage = _values?.float! % 100 ; 
    setClassNameToken2Sol(`rate_${classStage}`)
  };


  if (isBuyActive) {
    
    return(
      <div className="Sol_SSOLI_Exchange_Div">
    
        <p className="Sol_SSOLI_Exchange_Div_Label"> Amount Token </p>

        <div className="Sol_SSOLI_Exchange_Div_Currency">
          <div className="Currency_Input_Form_Div">
            <CurrencyInput
                id="validationCustom01"
                name="input-1"
                className={`form-control ${classNameSol2Token}`}
                onValueChange={handleOnValueChangeSol2Token}
                placeholder="Amount Sol"
                step={1}
                decimalsLimit={9}
            />
          </div>

          <PresalesButton userAnchorWallet={userAnchorWallet} solAmount={tokenAmountInput?.float!}/>
        
        </div>

        <div className="Token_Amount_Display_Div">
            <div className='Token_Amount_Display_Sub_Div'>
              {TokenAmountCalculator(tokenAmountInput!)}
            </div>
        </div>
      </div>
    )

  }else{

    return(
      
      <div className="Sol_SSOLI_Exchange_Div">
    
        <p className="Sol_SSOLI_Exchange_Div_Label"> Amount Sol </p>

        <div className="Sol_SSOLI_Exchange_Div_Currency">
          <div className="Currency_Input_Form_Div">
            <CurrencyInput
              id="validationCustom01"
              name="input-1"
              className={`form-control ${classNameToken2Sol}`}
              onValueChange={handleOnValueChangeToken2Sol}
              placeholder="Amount SOL"
              step={1}
              decimalsLimit={9}
              />
          </div>

          <RefundButton userAnchorWallet={userAnchorWallet} tokenAmount={solAmountInput?.float!}/>
        
        </div>
        
        <div className="Token_Amount_Display_Div">
          
          <div className='Token_Amount_Display_Sub_Div'>
            {SolAmountCalculator(solAmountInput!)}
          </div>
        
        </div>
      </div>

    );
  };

};

