import keypairJson from './keypairs.json'
// Solana Import 
import { 
  Connection, PublicKey,
  LAMPORTS_PER_SOL, clusterApiUrl
} from '@solana/web3.js';

import { AnchorWallet } from '@solana/wallet-adapter-react';

import { 
  AnchorProvider, Program,
  web3, BN, utils,
} from '@project-serum/anchor';


// External Import 
import idl from '../idl.json';

// Interface Define Section 
export interface sellTransactnButtonsInputDfns{
  userAnchorWallet : AnchorWallet | undefined;
  solAmount: number
}

export interface refundTransactnButtonsInputDfns{
  userAnchorWallet : AnchorWallet | undefined;
  tokenAmount: number
}

export interface walletInfoDivInputDfns{
  connection: Connection;
}

export interface userSalesAreaDivInputDfns{
  isBuyActive: boolean
}

interface providerInputDfns{
  userAnchorWallet : AnchorWallet | undefined;
}


// FUNCTION SECTION 
  

export async function GetAnchorProvider(
  { userAnchorWallet } : providerInputDfns ){

  if(!userAnchorWallet){
    return null;
  }
  console.log("ON GET PROVIDER");

  let anchorConnection = new Connection(clusterApiUrl('devnet'), "processed");
  
  console.log("SECOND STAGE")  
  
  let anchorProvider = new AnchorProvider(anchorConnection, userAnchorWallet, {"preflightCommitment": "processed"} )

  return anchorProvider;
};


export async function TokenBuyViaSmartContract(
  {userAnchorWallet, solAmount}:sellTransactnButtonsInputDfns) {

  // Public Key Define Section 
  let secretKey = Uint8Array.from(JSON.parse(keypairJson.ownerKeypair));
  let ownerKeypair =  web3.Keypair.fromSecretKey(secretKey);

  let mintPubKey: web3.PublicKey = new web3.PublicKey( keypairJson.mintPublicKey);
  let owner_one: web3.PublicKey = new web3.PublicKey( keypairJson.firstOwnerPublicKey );
  let owner_two: web3.PublicKey = new web3.PublicKey( keypairJson.secondOwnerPublicKey );
  let solSendByUser = solAmount * LAMPORTS_PER_SOL;

  // Token Address Generation or Finding 
  let ownerTokenAddress = await utils.token.associatedAddress({
    mint: mintPubKey,
    owner: ownerKeypair.publicKey
  });

  let buyerTokenAddress = await utils.token.associatedAddress({
    mint: mintPubKey,
    owner: userAnchorWallet!.publicKey,
  });


  // Provider Deploy Section 
  let programID = new PublicKey(idl.metadata.address)
  let anchorProvider = await GetAnchorProvider({userAnchorWallet});

  // Idf Sringify Section 
  let tempVar = JSON.stringify(idl);
  let programIDL = JSON.parse(tempVar);

  let anchorProgram = new Program(programIDL, programID, anchorProvider!);

  // Smart Contract Transaction Section 
  try{
    await anchorProgram.methods.sell(
      new BN(solSendByUser)
    )
    .accounts({
      mintAccount: mintPubKey,
      ownerTokenAcc: ownerTokenAddress,
      ownerAuthority: ownerKeypair.publicKey,
      buyerTokenAccount: buyerTokenAddress,
      buyerAuthority: userAnchorWallet!.publicKey,
      ownerAccOne: owner_one,
      ownerAccTwo: owner_two,
    })
    .signers([ownerKeypair])
    .rpc()

  }catch (err) {
      console.log("Transaction error: ", err);
    }
  

};

export async function TokenRefundViaSmartContract(
  {userAnchorWallet, tokenAmount}:refundTransactnButtonsInputDfns) {

  // Public Key Define Section 
  let secretKey = Uint8Array.from(JSON.parse(keypairJson.ownerKeypair));
  let ownerKeypair =  web3.Keypair.fromSecretKey(secretKey);

  let mintPubKey: web3.PublicKey = new web3.PublicKey( keypairJson.mintPublicKey);
  let TokenSendByUser = tokenAmount * LAMPORTS_PER_SOL;

  // Token Address Generation or Finding 
  let ownerTokenAddress = await utils.token.associatedAddress({
    mint: mintPubKey,
    owner: ownerKeypair.publicKey
  });

  let buyerTokenAddress = await utils.token.associatedAddress({
    mint: mintPubKey,
    owner: userAnchorWallet!.publicKey,
  });


  // Provider Deploy Section 
  let programID = new PublicKey(idl.metadata.address)
  let anchorProvider = await GetAnchorProvider({userAnchorWallet});

  // Idf Sringify Section 
  let tempVar = JSON.stringify(idl);
  let programIDL = JSON.parse(tempVar);

  let anchorProgram = new Program(programIDL, programID, anchorProvider!);

  // Smart Contract Transaction Section 
  try{
    await anchorProgram.methods.collect(
      new BN(TokenSendByUser)
    )
    .accounts({
      mintAccount: mintPubKey,
      ownerTokenAcc: ownerTokenAddress,
      ownerAuthority: ownerKeypair.publicKey,
      buyerTokenAccount: buyerTokenAddress,
      buyerAuthority: userAnchorWallet!.publicKey,
    })
    .signers([ownerKeypair])
    .rpc()

  }catch (err) {
      console.log("Transaction error: ", err);
    }
  

};