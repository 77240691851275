// React Import 
import React, {useEffect, useState} from 'react';
import TrackVisibility from 'react-on-screen';

// CSS Import 
import "./Home_Page.css"

// Third Part Import 
import ssoliLogo from './img/SSOLI.png';


const HomePage: React.FC = () => {

  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [ "Rapid", "Reliable", "Convinent", "Anti - Rugger" ];
  const period = 1000;


  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }


  return(
    <div className='Body_Div_Home_Page' id="home">
      
      <div className='Body_Div_Title_Div'>

        <TrackVisibility>
          <h1 className='Body_Div_Title_Div_Title'>
          Ladies and gentlemen, here we are with our art friendly meme token Solvador Soli. 
          SSOLI born with the surrealistic visions of Salvador Dalí. 
          Your  pall Salvador Soli is  is <br/>
            <span className="txt-rotate" data-rotate='[ "Web Developer", "Web Designer", "UI/UX Designer" ]'>
              <span className="wrap">
              {text}
              </span>
            </span>
          </h1>
        </TrackVisibility>
      
      </div>

      <div className='Body_Div_Icon_Div'>
        
        <div className='Body_Div_Icon_Div_Icon'>
        <img src={ssoliLogo} className='SSOLI_Icon'>
        </img>
        </div>

      </div>




    </div>
  )

}

export default HomePage;