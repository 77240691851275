import keypairJson from './keypairs.json'

// React Import
import { useEffect, useState} from "react";

// Solana Import 
import { LAMPORTS_PER_SOL, PublicKey} from "@solana/web3.js";
import { getAssociatedTokenAddress } from "@solana/spl-token";
import { useWallet} from "@solana/wallet-adapter-react";


// Third Part Import
import { UserSalesArea } from "./Wallet_Info_Div_Comps"; 
import { walletInfoDivInputDfns } from './Utils_Func';

import './Transaction_Page.css';


interface areaSwitchButtonHandInput {
  setIsBuyActive :  React.Dispatch<React.SetStateAction<boolean>>
}



const BuyAreaButtonClickHand = ({setIsBuyActive}:areaSwitchButtonHandInput)=>{
  setIsBuyActive(true);
}

const RefundAreaButtonClickHand = ({setIsBuyActive}:areaSwitchButtonHandInput)=>{
  setIsBuyActive(false);
}




export const WalletInfoDiv = ({connection}:walletInfoDivInputDfns) => {

  const userWallet = useWallet();
  const [accBalance, setAccBalance] = useState(0);
  const [ssoliBalance, setSsoliBalance] = useState(0);
  const [isBuyActive, setIsBuyActive] = useState(true);
  
  const mintPubKey: PublicKey = new PublicKey(keypairJson.mintPublicKey)


  useEffect(() => {
    if (!connection || !userWallet.publicKey) {
      return;
    }

    // SOL Balance Set 
    connection.onAccountChange( 
      userWallet.publicKey, 
      (updatedAccountInfo) => { setAccBalance( updatedAccountInfo.lamports / LAMPORTS_PER_SOL) },
      "confirmed",
    );

    connection.getAccountInfo(userWallet.publicKey).then( (info) => { 
      setAccBalance(info!.lamports / LAMPORTS_PER_SOL ) 
    });

    //SSOLI Balance Set
    (async () => {
      try{
        let userTokenPubkey = await getAssociatedTokenAddress(mintPubKey, userWallet.publicKey!);
        if (userTokenPubkey !== undefined) {
          let userSsoliBall = await connection.getTokenAccountBalance(userTokenPubkey);
          if (userSsoliBall.value.uiAmount !== null) {
          setSsoliBalance( userSsoliBall.value.uiAmount)
          }else{
            setSsoliBalance(0);
          }
        }else{
          setSsoliBalance(0);
        }
      }catch(err){
        console.log(err)
      }
    })();

  });
  
  
  // Wallet Connection Check
  if (userWallet.publicKey) {
    return(

      <div className= "Wallet_Div">
        <p className= "Wallet_PubKey"> {userWallet.publicKey.toBase58()} </p>

        <div className= "Wallet_Ball_Div_Table">
          <table width="100%">
            <tr>
              <td width="50%"> SOL Ball : </td>
              <td width="50%"> {accBalance }    </td>
            </tr>

            <tr>
              <td width="50%"> SSOLI Ball : </td>
              <td width="50%"> {ssoliBalance}    </td>
            </tr>
          </table>
        </div>
        
        <div className= "Wallet_Ball_Div_Trnsctn_Area">
          <div className= "Wallet_Bal_Div_Trnsctn_Type_Div">

            <button onClick={() => BuyAreaButtonClickHand({setIsBuyActive})} 
            className= { `Wallet_Bal_Div_Trnsctn_Type_Buy_Button ${isBuyActive ? 'Button_isActive' : ''}` } >
              Buy
            </button>
          
            <button onClick={() => RefundAreaButtonClickHand({setIsBuyActive})}
            className= { `Wallet_Bal_Div_Trnsctn_Type_Refund_Button ${isBuyActive ? '' : 'Button_isActive'}` } >
              Refund
            </button>

          </div>
          <UserSalesArea isBuyActive={isBuyActive} />
        </div>
      
      </div>
    
  );
  }else{
    return(
      <div className= "Wallet_Ball_Div_Empty">
      </div>
    );
  }
};

export default WalletInfoDiv;