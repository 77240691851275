import 
  { CurrencyInputOnChangeValues, formatValue } 
from 'react-currency-input-field';


export function TokenAmountCalculator(currenctInputJSON : CurrencyInputOnChangeValues){

  if (currenctInputJSON === undefined) {
    return(
      <div className="Token_Amount_Display_Div_Text">
        {formatValue({ value: String(" ") })} 
      </div>
    );
  }
  else{
    return(
      <div className="Token_Amount_Display_Div_Text">
        {formatValue({ value: String( currenctInputJSON!.float! * 81001 ) })} 
      </div>
      
    );
  }

}


export function SolAmountCalculator(currenctInputJSON : CurrencyInputOnChangeValues){

  if (currenctInputJSON === undefined) {
    return(
      <div className="Token_Amount_Display_Div_Text">
        {formatValue({ value: String(" ") })} 
      </div>
    );
  }
  else{
    return(
      <div className="Token_Amount_Display_Div_Text">
        {formatValue({ value: String( currenctInputJSON!.float! / 81001 ) })} 
      </div>
      
    );
  }

}