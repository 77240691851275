import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';

// CSS Import 

import "./Token_Info_Page.css"


const TokenInfoData = [
  { name: "Vault", value: 10 },
  { name: "LP", value: 45 },
  { name: "PreSales", value: 45 },
];

const GraphColors = ['#DCB9A1', '#846552', '#AE8F7A'];

const renderActiveShape = (props : any) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`P.V. ${value}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};


export default class TokenInfoPage extends PureComponent{

  // Function Section 
  state = {
    activeIndex: 0,
  };

  onPieEnter = (_ : any, index : any) => {
    this.setState({
      activeIndex: index,
    });
  };

  // Return Section 
  render(){
    return(
      <div className='Body_Div_Tokeneconocics' id="info">
        
        <div className='Body_Div_Tokeneconocics_Title'>

          <div className='Body_Div_Tokeneconocics_Title_Title'>
            <p>Tokeneconomics</p>
          </div>
          <div className='Body_Div_Tokeneconocics_Title_Line  '>

          </div>

        </div>

        <div className='Body_Div_CardBoard' >
          
          <div className='Body_Div_CardB_Graph'>

            <h2 className='Body_Div_Graph_Div_Title'>Tokenomics Graph</h2>

            <PieChart width={600} height={500} className='Body_Div_Graph_Div_Graph'>

              <Pie
                cx="50%"
                cy="50%"
                data={TokenInfoData}  
                fill="#8884d8"
                dataKey="value"
                innerRadius={100}
                outerRadius={150}
                blendStroke = {true}
                paddingAngle={5}
                onMouseEnter={this.onPieEnter}
                activeShape={renderActiveShape}
                activeIndex={this.state.activeIndex}
                >
                {TokenInfoData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={GraphColors[index % GraphColors.length]} />
                  ))}

              </Pie>

            </PieChart>

          </div>            

          <div className='Body_Div_Text_Div'>

            <span className='Body_Div_Text_Div_Text_One'>
            Token distribution for a project like 45% of the tokens are allocated to 
            liquidity providers, another 45% to the presale, and 10% to the vault. Liquidity providers' 
            tokens support market trading, presale tokens are for investors backing the project, and the 
            vault's 10% is used for project development, marketing, and operational expenses.

            </span>

            <span className='Body_Div_Text_Div_Text_Two'>
            10 percent of mined SSOLI tokens will be distributed as prizes in competitions after 
            the website update. The initial liquidity pool will add 90% of the SOL obtained in 
            the presale. The remaining 10% will cover project expenses and develop the next project, 
            which will be airdropped to SSOLI holders.
            </span>

          </div>

        </div>

      </div>
    )

  }
}
