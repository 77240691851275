// React Import 
import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';

// Third Parti Import 
import soliLogo from './img/Soli_icon.svg';

// CSS Import 
import './Common_items.css';


const MainNavBar: React.FC = (props) => {


  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
      const handleScroll = () => {
          if (window.scrollY > 100) { 
              setScrolled(true);
          } else {
              setScrolled(false);
          }
      };

      window.addEventListener('scroll', handleScroll);
      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);

  return (
    <div className= { `Nav_Bar_Cont ${scrolled ? 'Small' : 'Large'}` }>

      <Navbar className= "Nav_Bar_Main" >

        <a href='#home'>
        <img className= { `Navi_Bar_Brand_Icon ${scrolled ? 'Sub_Icon' : ''}` } alt="" src={soliLogo} />{' '}
        </a>
        <Navbar.Brand className= { `Nav_Bar_Brand_Text ${scrolled ? '' : 'Sub_Items'}` }  href="#home">
          Salvodor Soli
        </Navbar.Brand>
          

        <Nav className="me-auto">
          
          <Nav.Link className= { `Nav_Bar_Sub_Title ${scrolled ? '' : 'Sub_Items'}` } color='green' href="#info"> 
            Token Info 
          </Nav.Link>
          
          <Nav.Link className= { `Nav_Bar_Sub_Title ${scrolled ? '' : 'Sub_Items'}` }  href="#transaction"> 
            Token Transaction
          </Nav.Link>

          <Nav.Link className= { `Nav_Bar_Sub_Title ${scrolled ? '' : 'Sub_Items'}` }  href="#roadMap"> 
            Token Road Map
          </Nav.Link>
          
        </Nav>

      </Navbar>
    </div>
  );

}


export default MainNavBar;

