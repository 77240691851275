// React Import 
import React, { useState, useEffect } from 'react';
import { 
  PieChart, Pie,Tooltip, 
  Cell,ResponsiveContainer 
} from 'recharts';

// Solana Import 
import { PublicKey }from "@solana/web3.js";
import { useConnection }from "@solana/wallet-adapter-react";

import { 
  WalletModalProvider, WalletMultiButton 
} from "@solana/wallet-adapter-react-ui";

// Third Party Import 
import WalletInfoDiv from './Wallet_Info_Div';
import keypairJson from './keypairs.json'


//CSS Import
import "@solana/wallet-adapter-react-ui/styles.css"
import './Transaction_Page.css';


const TransactionPage: React.FC = () => {
  // Classical Function Section 
  
  const { connection } = useConnection();
  const [soldTokenAmmount, setSoldTokenAmmount] = useState(0);

  const mainTokenPubKey: PublicKey = new PublicKey(keypairJson.ownerTokenPublicKey);
  
  const pieChartColors = ["#AE8F7A","#846552"];



  const preSalesTokenData = [
    { name: 'Redy to Sold', value: 450_000_000 - soldTokenAmmount },
    { name: 'Sold Token Amount', value: soldTokenAmmount },
  ];

  useEffect(() => {

    (async () => {
      let temp = await connection.getTokenAccountBalance(mainTokenPubKey);
      console.log(Number(temp.value.uiAmount!));
      setSoldTokenAmmount(1_000_000_000 - Math.floor(temp.value.uiAmount!))
    })();

  });



  // Web site Design Section 
  return (
    <div className="Body_Div_Transaction_Page" id="transaction">
      
      <div className='Body_Div_Transaction_Page_Title'>

        <div className='Body_Div_Transaction_Page_Title_Title'>
          <p>Transaction </p>
        </div>

        <div className='Body_Div_Transaction_Page_Title_Line  '>

        </div>

      </div>

      <div className='Body_Div_Transaction_Page_Boddy'>

        <div className= "Body_Div_Wallet">
          <div className= "Body_Div_Wallet_Info">

            <div className="Body_Div_Wallet_Info_Text">
            <WalletInfoDiv connection={connection}/>
            </div>

            <div className="Body_Div_Wallet_Info_Button">
              <WalletModalProvider>
                <WalletMultiButton  />
              </WalletModalProvider>
            </div>

          </div>
        </div>
        
        
        <div className= "Body_Div_Main_Page">
          <ResponsiveContainer width="100%" height="100%">
    
            <PieChart width={600} height={600}>
              <Pie
                cx = "50%"
                cy = "30%"
                dataKey = "value"
                labelLine = {false}
                outerRadius = {200}
                innerRadius = {150}
                paddingAngle = {5}
                blendStroke = {true}
                isAnimationActive = {true}
                data = {preSalesTokenData}
                animationEasing = "ease-in"
                animationDuration={2000}
                label

              > 
              {preSalesTokenData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={pieChartColors[index % pieChartColors.length]} />
              ))}
              </Pie>
      
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>

        </div>
      
        </div>
    </div>
  );
}

export default TransactionPage;