
// React Import 
import React from 'react';

// Solana Import 

// Solana Import 
import { clusterApiUrl }from "@solana/web3.js";
import { 
  ConnectionProvider, WalletProvider,
} from "@solana/wallet-adapter-react";

// Third Party Import 
import HomePage from './home_page/Home_Page';
import MainNavBar from './common_items/Nav_Bar';
import TokenInfoPage from './token_info_page/Token_Info_Page';
import TransactionPage from './transaction_page/Transaction_Page';
import RoadMapPage from './road_map_page/Road_Map_Page';

import { Routes, Route } from 'react-router-dom';



//CSS Import
import './App.css';


const App: React.FC = () => {

  const endpoint = clusterApiUrl("devnet");
  const wallets = React.useMemo(() => [], []);

  // Web site Design Section 
  return (
    <ConnectionProvider endpoint={endpoint}>
    <WalletProvider wallets={wallets}>

      <body className="App_Body">
        <MainNavBar/>
        <HomePage/>
        <TokenInfoPage/>
        <TransactionPage/>
        <RoadMapPage/>
      </body>

    </WalletProvider>
    </ConnectionProvider>
  );
}

export default App;

/* 
<Route path='/' element={<HomePage/>} />
<Route path='/info' element={<TokenInfoPage/>} />
<Route path="/transaction" element={<TransactionPage/>}   />
*/